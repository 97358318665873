@use "sass:math";

@mixin breakpoint($point) {
  @if $point == widescreen {
    @media (min-width: 1224px) { @content; } }
  @else if $point == desktop {
    @media (min-width: 769px) { @content; } }
  @else if $point == tablet {
    @media (min-width: 481px) { @content; } }
  @else if $point == mobile {
    @media (max-width: 480px)  { @content; } }
  @else if $point == mini {
    @media (min-width: 260px)  { @content; } } }

$pad-base: 12px;
$pad-mobile: $pad-base * 1.5;
$pad-tablet: $pad-base * 1;
$pad-desktop: $pad-base * 1.5;
$pad-widescreen: $pad-base * 2;

@mixin pad-rwd {
  @include breakpoint(mobile) {
    padding: $pad-mobile; }
  @include breakpoint(tablet) {
    padding: $pad-tablet; }
  @include breakpoint(desktop) {
    padding: $pad-desktop; }
  @include breakpoint(widescreen) {
    padding: $pad-widescreen; }
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

header {
  z-index: 1;
  position: fixed;
  color: white;
  background-color: #6c8c3c;
  width: 100%;
  top: 0;

  div.logo {
    background: url("/images/logo.png") no-repeat;
    background-size: 60px 60px;
    display: inline-block;
    padding: 10px 30px;
    margin: 10px;
    height: 60px;
    line-height: 60px;
    @include breakpoint(mobile) {
      width: 20%;
    }
    @include breakpoint(tablet) {
      width: 50%;
    }
    @include breakpoint(desktop) {
      width: 25%;
    }
    @include breakpoint(widescreen) {
    }
  }
}



/* APP STORE ICONS */

a.app-store-icons {
  text-decoration: none;
}


/* TGW FOOTER STYLES */

.tgw-logo {
  font-family: 'nixie', sans-serif;
  color: #EFF3F3;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: 1224px) {
    font-size: 1.1em;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    font-size: 1.0em;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    font-size: 0.9em;
  }
  @media(max-width: 480px) {
    font-size: 0.8em;
  }
}

.tgw-credit {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 40px;

  a {
    text-decoration: none;
    color: #EFF3F3;
    font-family: 'nixie', sans-serif;
  }

  @media (min-width: 1224px) {
    font-size: 1.1em;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    font-size: 1.0em;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    font-size: 0.9em;
  }
  @media(max-width: 480px) {
    font-size: 0.8em;
  }
}

.tgw-title-icon {
  width: calc(2.2 * 1em);
  vertical-align: middle;
}







@font-face {
  font-family: 'montserrat-light';
  src: url("/fonts/Montserrat-Light.eot");
  src: url("/fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("/fonts/Montserrat-Light.woff") format("woff"), url("/fonts/Montserrat-Light.ttf") format("truetype"), url("/fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-reg';
  src: url("/fonts/Montserrat-Regular.eot");
  src: url("/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Montserrat-Regular.woff") format("woff"), url("/fonts/Montserrat-Regular.ttf") format("truetype"), url("/fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'shadows';
  src: url("/fonts/ShadowsIntoLight.eot");
  src: url("/fonts/ShadowsIntoLight.eot?#iefix") format("embedded-opentype"), url("/fonts/ShadowsIntoLight.woff") format("woff"), url("/fonts/ShadowsIntoLight.ttf") format("truetype"), url("/fonts/ShadowsIntoLight.svg#ShadowsIntoLight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nixie';
  src: url('/fonts/NixieOne-Regular.eot');
  src: url('/fonts/NixieOne-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/NixieOne-Regular.woff') format('woff'),
  url('/fonts/NixieOne-Regular.ttf') format('truetype'),
  url('/fonts/NixieOne-Regular.svg#NixieOne-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}









.nav {

  display: inline-block;
  margin-top: 20px;
  float:right;
  border-radius: 5px;

  @media (min-width: 1224px) {
    background-size: 50px 50px;
    padding: 10px 30px 10px 20px;
    line-height: 50px;
    height: 50px;
    margin-right: 20px;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    background-size: 50px 50px;
    padding: 10px 30px 10px 20px;
    line-height: 50px;
    height: 50px;
    margin-right: 20px;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    background-size: 50px 50px;
    padding: 10px 30px 10px 20px;
    line-height: 50px;
    height: 50px;
    margin-right: 20px;
  }
  @media(max-width: 480px) {
    background-size: 25px 25px;
    padding: 5px 15px 5px 10px;
    line-height: 25px;
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }
}

.store-icons {
  padding-bottom: 50px;
  text-align: center;
}

p.clear {clear: both}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.app-copy {
  text-align: left;

  @media (min-width: 1224px) {
    font-size: 1em;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    font-size: .9em;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    font-size: .8em;
  }
  @media(max-width: 480px) {
    font-size: .7em;
  }
}

.app-description {
  text-align: center;
}

img.app-image {
  height: 15em;
  margin-right: 15px;
  border-radius: 5px;
  vertical-align: middle;


  @media (min-width: 1224px) {
    float: left;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    float: left;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    float: left;
  }
  @media(max-width: 480px) {
    text-align: center;
    margin-top: 10px;
  }
}

.place-content {
  text-align: left;

  @media(max-width: 480px) {
    text-align: center;
  }
}

.store-icons {
  padding-bottom: 20px;
  text-align: center;
}


*,
*:before,
*:after {
  box-sizing: border-box;
}

@media (min-width: 1224px) {
  h1 { font-size: 2em; }
  h2 { font-size: 1.75em; }
  h3 { font-size: 1.5em; }
}
@media(min-width: 769px) and (max-width: 1223px) {
  h1 { font-size: 1.35em; }
  h2 { font-size: 1.25em; }
  h3 { font-size: 1.1em; }
}
@media(min-width: 481px) and (max-width: 768px) {
  h1 { font-size: 1.25em; }
  h2 { font-size: 1em; }
  h3 { font-size: .9em; }
}
@media(max-width: 480px) {
  h1 { font-size: 1em; }
  h2 { font-size: .8em; }
  h3 { font-size: .7em; }
}

svg {
  max-width: 100%;
  height: auto;
  display: block;
}

.footer {
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.product-desc {
  color: #36454A;
  font-size: large;
  font-family: montserrat-light;
}

.ip {
  text-align: center;

  @media (min-width: 1224px) {
    font-size: 2em;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    font-size: 1.7em;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    font-size: 1.5em;
  }
  @media(max-width: 480px) {
    font-size: 1.1em;
  }
}


.ogt {
  text-align: center;

  @media (min-width: 1224px) {
    font-size: 2em;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    font-size: 1.7em;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    font-size: 1.5em;
  }
  @media(max-width: 480px) {
    font-size: 1.1em;
  }
}

.jumbotron {
  z-index: 1;
  width: 90%;

  @media (min-width: 1224px) {
    margin: 10% auto 0 auto;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    margin: 15% auto 0 auto;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    margin: 15% auto 0 auto;
  }
  @media(max-width: 480px) {
    margin: 35% auto 0 auto;
  }
}

header div.logo {
  background-color: transparent;
}

.container-fluid {
  overflow: hidden;
}


.jumbotron.first {
  @media (min-width: 1224px) {
    margin: 40% auto auto auto;
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    margin: 90% auto auto auto;
  }
  @media(min-width: 481px) and (max-width: 768px) {
    margin: 90% auto auto auto;
  }
  @media(max-width: 480px) {
    margin: 400px auto auto auto;
  }
}

.hero {
  img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 0;

    @media (min-width: 1441px) {
      top: -300px;
      width: 25%;
    }
    @media (min-width: 1224px) and (max-width: 1440px) {
      top: -120px;
      width: 25%;
    }
    @media(min-width: 769px) and (max-width: 1223px) {
      top: -400px;
      width: 50%;
    }
    @media(min-width: 481px) and (max-width: 768px) {
      top: -300px;
      width: 50%;
    }
    @media(max-width: 480px) {
      top: -300px;
      width: 30%;
    }
  }

  div {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    color: #fff;
    text-align: center;
    z-index: 0;

    @include pad-rwd;

    h1 {
      font-weight: 600;
      z-index: 0;
    }

    p {
      margin-top: 1rem;
      z-index: 0;
    }

    @media (min-width: 1441px) {
      top: 337px;
      width: 100%;
      h1 { font-size: 4em; }
      p { font-size: 3em; }
    }
    @media (min-width: 1224px) and (max-width: 1440px) {
      top: 200px;
      width: 80%;
      h1 { font-size: 3em; }
      p { font-size: 2em; }
    }
    @media(min-width: 769px) and (max-width: 1223px) {
      top: 280px;
      width: 100%;
      h1 { font-size: 3em; }
      p { font-size: 2em; }
    }
    @media(min-width: 481px) and (max-width: 768px) {
      top: 280px;
      width: 100%;
      h1 { font-size: 2.5em; }
      p { font-size: 1.7em; }
    }
    @media(max-width: 480px) {
      top: 200px;
      width: 100%;
      h1 { font-size: 1.5em; }
      p { font-size: 1.0em; }
    }
  }
}

.app-details {
  font-family: 'montserrat-reg', sans-serif;

  p {
    color: #36454A;
    font-size: 1.6em;
    font-family: 'montserrat-reg', sans-serif;
  }

  .app-icon {
    height: 60px;
    width: 60px;
    border-radius: 11px;
    vertical-align: middle;
  }

  .app-copy {
    text-align: left;
    font-size: 1.2em;
  }

  a.text-lg {
    text-decoration: none;
    color: #36454A;
    vertical-align: middle;
    padding-left: 20px;
  }

  a.text-md {
    text-decoration: none;
    color: #36454A;
    vertical-align: middle;
    padding-left: 20px;
  }

  @media (min-width: 1224px) {
    a.text-lg { font-size: 1.8em; }
    a.text-md { font-size: 1.3em; }
  }
  @media(min-width: 769px) and (max-width: 1223px) {
    a.text-lg { font-size: 1.7em; }
    a.text-md { font-size: 1.2em; }
  }
  @media(min-width: 481px) and (max-width: 768px) {
    a.text-lg { font-size: 1.6em; }
    a.text-md { font-size: 1.1em; }
  }
  @media(max-width: 480px) {
    padding: 10px;

    a.text-lg {
      font-size: 0.9em;
      padding-left: 0;
    }
    .app-copy {
      font-size: 1.0em;
    }
    a.text-md {
      font-size: 0.8em;
      text-align: left;
    }
  }

  .place-content {
    text-align: left;

    @media(max-width: 480px) {
      text-align: center;
    }
  }

  .app-image {
    height: 15em;
    margin-right: 15px;
    border-radius: 5px;

    @media (min-width: 1224px) {
      float: left;
    }
    @media(min-width: 769px) and (max-width: 1223px) {
      float: left;
    }
    @media(min-width: 481px) and (max-width: 768px) {
      float: left;
    }
    @media(max-width: 480px) {
      text-align: center;
    }
  }
}

.inner {
  height: 100%;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
}


.app-privacy-section {
  font-weight: bolder;
  font-variant: all-petite-caps;
}